/**
 * Created by sfraoua on 22/02/2018.
 */
import {Message} from "@app/core/messaging/message";
import {SessionState} from "@app/shared/models/session";

export class TouchGesture extends Message{
    gestureType : TouchGestureType;
    event: MouseEventType|TouchEventType;
    x : number;
    y : number;
}

export enum TouchGestureType {
    SCROLL = 0,
    MOVE = 1,
    CANCEL = 2,
    CLICK = 3,
    DOWN = 4,
    UP = 5
}

export enum EventGestureCode {
    MOVE = 'm',
    CLICK = 'click',
    DOWN = 'd',
    UP = 'u'
}

export enum MouseEventType {
    MOUSE_LEAVE = 'mouseleave',
    MOUSE_UP = 'mouseup',
    MOUSE_DOWN = 'mousedown',
    CLICK = 'click',
    MOUSE_MOVE = 'mousemove'
}

export enum TouchEventType {
    TOUCH_MOVE = 'touchmove',
    TOUCH_END = 'touchend',
    TOUCH_CANCEL= 'touchcancel',
    TOUCH_START = 'touchstart'
}
