import {Component, OnDestroy, OnInit} from '@angular/core';
import {
    PresentationEvent,
    PresentationEventsService,
    PresentationResponse
} from '@app/home/session/presentation/presentation-events.service';
import {Subscription} from 'rxjs';
import {MessagingService} from '@app/core/messaging/messaging.service';
import {filter} from 'rxjs/operators';
import {MessageInterface} from '@app/core/messaging/message';
import {TouchGesture} from '@app/core/messaging/touch-gesture';
import {PresenterControl} from '@app/core/messaging/presenter-control';
import {InteractiveModeEnableCommand} from '@app/core/messaging/interactive-mode-enable-command';
import {AuthenticationService} from '@app/core/authentication/authentication.service';
import {SdkService} from '@app/shared/service/sdk.service';
import {AllowStreaming} from '@app/core/messaging/allow-streaming';
import {ConferenceEnableCommand} from '@app/core/messaging/conference-enable-command';

@Component({
    selector: 'app-presenter-control',
    templateUrl: './presenter-control.component.html',
    styleUrls: ['./presenter-control.component.scss']
})
export class PresenterControlComponent implements OnInit, OnDestroy {

    private _subscription: Subscription[] = [];
    public hasPresenterMode: boolean = false;
    public hasInteractiveMode: boolean;
    public askingForHand: boolean = false;
    public showGetControl: boolean = false;
    public showLostControl: boolean = false;
    public showGetInteractiveMode: boolean;
    public showLostInteractiveMode: boolean;

    private sdk: any;

    /**
     * @function constructor
     * @param {PresentationEventsService} _eventsService
     * @param {MessagingService} _messagingService
     * @param {AuthenticationService} _authService
     */
    constructor(
        private _eventsService: PresentationEventsService,
        private _messagingService: MessagingService,
        private _authService: AuthenticationService,
        private _sdkService: SdkService
    ) {
        // subscribe to presentation messages
        this._subscription.push(
            this._eventsService.actionRequests
                .subscribe(
                    (action: PresentationResponse) => {
                        switch (action.event) {
                            case PresentationEvent.askForHandNotification:
                                this._getHandAction(action.data);
                                break;
                            case PresentationEvent.interactiveModeStatusNotification:
                                this._toggleInteractiveModeAction(action.data);
                                break;
                            case PresentationEvent.ToWaitingRoom:
                                if (this.hasPresenterMode) {
                                    this._messagingService.leaveHand();
                                }
                                break;
                        }
                    }
                ));


        this._subscription.push(
            this._messagingService.Messages.subscribe((message: MessageInterface) => {
                 switch (message.constructor) {
                   case PresenterControl:
                     const PresenterControlMessage = <PresenterControl>message;
                     if (PresenterControlMessage.presenterID === this._authService.credentials.username) {
                       this._handControl(PresenterControlMessage);
                     } else if (this.hasPresenterMode) {
                       this._handControl(PresenterControlMessage);
                     }
                     break;
                   case InteractiveModeEnableCommand:
                     this._toggleInteractiveMode(<InteractiveModeEnableCommand>message);
                     break;
                 }
                }));
        this._subscription.push(
          this._messagingService.ReplayMessages.subscribe((message: MessageInterface) => {
            switch (message.constructor) {
              case InteractiveModeEnableCommand:
                this._toggleInteractiveMode(<InteractiveModeEnableCommand>message);
                break;
            }
          }));
    }

    /**
     * @function ngOnInit
     */
    ngOnInit() {
        // this._sdkService.initSDK();
        this.sdk = this._sdkService.sdk;
    }

    ngOnDestroy() {
        this._subscription.forEach((subscription: Subscription) =>
            subscription.unsubscribe()
        );
    }

    /**
     * @function _handControl
     * @description
     * @param {PresenterControl} message
     * @private
     * @returns {void}
     */
    private _handControl(message: PresenterControl): void {
        if (!this.hasPresenterMode) {
            this.hasPresenterMode = true;
            this._getHandAction(this.hasPresenterMode);
        } else {
            this.hasPresenterMode = false;
            this._getHandAction(this.hasPresenterMode);
        }
        this._eventsService.isHandNotification(this.hasPresenterMode);
        this.sdk.getPlayerJS().eventDispatcher({'type': 'presenter-mode', 'status': this.hasPresenterMode});
    }

    /**
     * @function _toggleInteractiveMode
     * @description
     * @param {InteractiveModeEnableCommand} message
     * @private
     * @returns {void}
     */
    private _toggleInteractiveMode(message: InteractiveModeEnableCommand): void {
        this.hasInteractiveMode = message.isEnabled;
        this._toggleInteractiveModeAction(this.hasInteractiveMode);
        this._eventsService.interactiveModeStatusNotification(this.hasInteractiveMode);
        this._sdkService.injectScript().then(() => {
          if (!this.sdk) {
            this.sdk = this._sdkService.sdk;
          }
          this.sdk.getPlayerJS().eventDispatcher({ 'type': 'interactive-mode', 'status': this.hasInteractiveMode });
        });
    }

    /**
     * @function askForHandAction
     * @description
     * @public
     * @returns {void}
     */
    public askForHandAction(): void {
        this.askingForHand = true;
        this.hasPresenterMode ? this._messagingService.leaveHand() : this._messagingService.askForHand();
    }

    /**
     * @function _getHandAction
     * @description
     * @param {boolean} hasPresenterMode
     * @private
     * @returns {void}
     */
    private _getHandAction(hasPresenterMode: boolean): void {
        this.askingForHand = false;
        this.hasPresenterMode = hasPresenterMode;
        if (!hasPresenterMode) {
            // Displaying lost control icon
            this.showLostControl = true;
            // notify the SDK to losing control
            // this.sdk.getPlayerJS().eventDispatcher({'type': 'presenter-mode', 'status': this.hasPresenterMode});
            setTimeout(() => {
                this.showLostControl = false;
            }, 3000);
        } else {
            // Displaying get control icon
            this.showGetControl = true;
            setTimeout(() => {
                this.showGetControl = false;
            }, 3000);
        }
    }

    /**
     * @function _toggleInteractiveModeAction
     * @description
     * @param {boolean} hasInteractiveMode
     * @private
     * @returns {void}
     */
    private _toggleInteractiveModeAction(hasInteractiveMode: boolean): void {

      if (this.hasPresenterMode && !hasInteractiveMode) {
        this.showLostInteractiveMode = true;
        setTimeout(() => {
          this.showLostInteractiveMode = false;
        }, 3000);
      } else if (!this.hasPresenterMode && hasInteractiveMode) {
        this.showGetInteractiveMode = true;
        setTimeout(() => {
          this.showGetInteractiveMode = false;
        }, 3000);
      }
      this.hasPresenterMode = hasInteractiveMode;

    }

}
