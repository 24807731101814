import {Injectable} from '@angular/core';
import {environment} from '@env/environment';
import {fromEvent} from 'rxjs';
import {Logger} from '@app/core/logger.service';
import {Resolve} from '@angular/router';

declare var SDK: any;
const log = new Logger('SDK JS');

export enum SDK_EVENTS {
  TOUCH_EVENT = 'touch-gesture',
  VIDEO_EVENT = 'video-action',
  NOTIFICATION = 'notification',
  LOAD_SLIDE = 'load-slide',
  SLIDE_LOADED_EVENT = 'slide-loaded-event'
}


@Injectable()
export class SdkService {

    private _sdk: any;
    private isLoading: boolean = false;
    private _sdkJsPlayerScript: any = null;


    constructor() {}


    public injectScript() {
      return new Promise((resolve, reject) => {
        if (!this._sdk) {

          // only one injection
          if (!this.isLoading) {
            this.isLoading = true;
            const hash = new Date().getTime();
            this._sdkJsPlayerScript = document.createElement('script');
            this._sdkJsPlayerScript.type = 'text/javascript';
            this._sdkJsPlayerScript.src = environment.sdkJsPlayer + '?' + hash;

            document.getElementsByTagName('head')[0].appendChild(this._sdkJsPlayerScript);
            log.info('sdk JS Player script injected');
          }
            fromEvent(this._sdkJsPlayerScript, 'load').subscribe((event: Event) => {
              this.initSDK();
              resolve();
            });
        } else {
          resolve();
        }
      });
    }

    public initSDK() {
      // init the SDK JS Module at every load of the presentation component.
      // (prevent the lost of subscriptions of the rxjs subjects of the JS SDK) init the subscription at the load
      if (!this._sdk) {
        this._sdk = new SDK.Bootstrap('player-js-container');
      }
    }

    /**
     * @function sdk
     * @description
     * @public
     * @returns {void}
     */
    public get sdk(): any {
      return this._sdk;
    }


}
