import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { ServiceWorkerModule } from '@angular/service-worker';
import { registerLocaleData } from '@angular/common';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AlertModule } from 'ngx-bootstrap';

import { CoreModule } from '@app/core/core.module';
import { LoginModule } from '@app/login/login.module';
import { ThanksPageModule } from '@app/thanks-page/thanks-page.module';
import { HomeModule } from '@app/home/home.module';
import { SharedModule } from '@app/shared/shared.module';
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { GrpdComponent } from './login/grpd/grpd.component';
import { RecoverPwdComponent } from './login/recover-pwd/recover-pwd.component';

import { HeaderService } from '@app/core/shell/header/header.service';
import { FooterService } from '@app/core/shell/footer/footer.service';
import { GtuService } from '@app/shared/service/gtu.service';
import { PollService } from '@app/shared/service/poll.service';
import { UserService } from '@app/shared/service/user.service';
import { SessionService } from '@app/shared/service/session.service';
import { ContentService } from '@app/shared/service/content.service';
import { RelatedService } from '@app/shared/service/related.service';
import { ContentCacheService } from '@app/shared/service/content-cache.service';
import { SettingModule } from '@app/setting/setting.module';
import { AttendeesService } from '@app/shared/service/attendees.service';
import { ClientService } from '@app/shared/service/client.service';
import { VersionService } from '@app/shared/service/version.service';
import { UtilService } from '@app/shared/service/util.service';
import { BowserModule, BowserService } from 'ngx-bowser';
import { BrowserService } from '@app/shared/service/browser.service';
import { SdkService } from '@app/shared/service/sdk.service';
import { CustoService } from '@app/shared/service/custo.service';

import localeEnUs from '@angular/common/locales/en-US-POSIX';
import localeFrFr from '@angular/common/locales/fr';
import localeZhCN from '@angular/common/locales/zh';

registerLocaleData(localeEnUs);
registerLocaleData(localeFrFr);
registerLocaleData(localeZhCN);

@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        ServiceWorkerModule.register('/ngsw-worker.js', { enabled: false }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        NgbModule.forRoot(),
        AlertModule.forRoot(),
        CoreModule,
        LoginModule,
        ThanksPageModule,
        HomeModule,
        SharedModule,
        AppRoutingModule,
        SettingModule,
        BowserModule
    ],
    declarations: [
        AppComponent
    ],
    providers: [
        HeaderService,
        GtuService,
        PollService,
        UserService,
        ClientService,
        SessionService,
        ContentService,
        RelatedService,
        ContentCacheService,
        FooterService,
        AttendeesService,
        VersionService,
        UtilService,
        BowserService,
        BrowserService,
        SdkService,
        CustoService
    ],
    bootstrap: [
        AppComponent
    ],
    entryComponents: [
        GrpdComponent,
        RecoverPwdComponent
    ]
 })
export class AppModule {}

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, '../assets/translations/', '.json');
}