import {Injectable} from '@angular/core';
import {Subject, ReplaySubject} from "rxjs";
import {TouchGesture} from "@app/core/messaging/touch-gesture";
import {RelatedContent} from '@app/shared/models/related_content';
import {RemoteRelatedFile} from '@app/core/messaging/remote-related-file';

export class PresentationResponse {
    public event: PresentationEvent;
    public data: any;

    constructor(type: PresentationEvent, data: any = null) {
        this.event = type;
        this.data = data;
    }
}

export enum PresentationEvent {
    ToWaitingRoom = "to_w_room",

    ChatDisplay = "chat_display",
    ChatClose = "chat_close",
    ChatDisplayed = "chat_displayed",
    ChatClosed = "chat_closed",

    DisplayPresentationActionBars = "display_action_bars",
    HidePresentationActionBars = "hide_action_bars",
    ClearDrawedShapes = "clear_drawed_shapes",
    LaunchEventOnContent = "launch_event_on_content",
    ToggleCamera = "toggle_camera",
    ToggleMicro = "toggle_micro",
    StreamingAllowed = "streaming_allowed",
    StreamingDisabled = "streaming_disabled",
    relatedContentClick = 'related_content_click',
    relatedContentClickAction = 'related_content_click_action',
    remoteRelatedContentAction = 'remote_related_content_action',
    remoteRelatedContentClickAction = 'remote_related_content_click_action',
    presenterControlClick = 'presenter_control_click',
    askForHandNotification = 'ask_hand_notification',
    interactiveModeStatusNotification = 'interactive_mode_status_notification',
    collapseDrawer = 'collapse_drawer',
    closePoll = 'close_poll',
    notification = 'notification',
    isChatEnabled = 'is_chat_enabled',
    isConferenceEnabled = 'is_conference_enabled',
    ConferenceCanConnect = 'conference-can-connect'
}

@Injectable()
export class PresentationEventsService {

    constructor() {
    }

    private _actionRequests: Subject<PresentationResponse> = new Subject<PresentationResponse>();
    private _actionRequestsReplay: ReplaySubject<PresentationResponse> = new ReplaySubject<PresentationResponse>();

    get actionRequests(): Subject<PresentationResponse> {
        return this._actionRequests;
    }

    get actionRequestsReplay(): ReplaySubject<PresentationResponse> {
      return this._actionRequestsReplay;
    }

    public goToWaitingRoom() {
        this._actionRequests.next(new PresentationResponse(PresentationEvent.ToWaitingRoom))
    }

    public showChatPanel(show: boolean = true) {
        if (show)
            this._actionRequests.next(new PresentationResponse(PresentationEvent.ChatDisplay));
        else
            this._actionRequests.next(new PresentationResponse(PresentationEvent.ChatClose));
    }

    public notifyChatVisibiltyChanged(displayed: boolean = true) {
        if (displayed)
            this._actionRequests.next(new PresentationResponse(PresentationEvent.ChatDisplayed));
        else
            this._actionRequests.next(new PresentationResponse(PresentationEvent.ChatClosed));
    }

    public clearDrawedShapes() {
        this._actionRequests.next(new PresentationResponse(PresentationEvent.ClearDrawedShapes));
    }

    public launchEvent(touch: TouchGesture) {
        this._actionRequests.next(new PresentationResponse(PresentationEvent.LaunchEventOnContent, touch));
    }

    public toggleVideo() {
        this._actionRequests.next(new PresentationResponse(PresentationEvent.ToggleCamera));
    }

    public toggleMicro() {
        this._actionRequests.next(new PresentationResponse(PresentationEvent.ToggleMicro));
    }

    public streamingAllowedNotify() {
        this._actionRequests.next(new PresentationResponse(PresentationEvent.StreamingAllowed));
    }

    public streamingDisabledNotify() {
        this._actionRequests.next(new PresentationResponse(PresentationEvent.StreamingDisabled));
    }

    public relatedContentClicked() {
      this._actionRequests.next(new PresentationResponse(PresentationEvent.relatedContentClick));
    }

    public relatedContentClickAction(related: RelatedContent) {
      this._actionRequests.next(new PresentationResponse(PresentationEvent.relatedContentClickAction, related));
    }

    public remoteRelatedContentAction(related: any) {
      this._actionRequestsReplay.next(new PresentationResponse(PresentationEvent.remoteRelatedContentAction, related));
    }

    public remoteRelatedContentClickAction(related: any) {
      this._actionRequests.next(new PresentationResponse(PresentationEvent.remoteRelatedContentClickAction, related));
    }

    public isHandNotification(handIsActive: boolean) {
      this._actionRequests.next(new PresentationResponse(PresentationEvent.askForHandNotification, handIsActive));
    }

    public interactiveModeStatusNotification(isEnabled: boolean) {
      this._actionRequests.next(new PresentationResponse(PresentationEvent.interactiveModeStatusNotification, isEnabled));
    }

    public showDrawerAction() {
      this._actionRequests.next(new PresentationResponse(PresentationEvent.collapseDrawer));
    }

    public closePollAction() {
      this._actionRequests.next(new PresentationResponse(PresentationEvent.closePoll));
    }

    public notificationAction(message: any) {
        this._actionRequests.next(new PresentationResponse(PresentationEvent.notification, message));
    }

    public isChatEnabled(message: boolean) {
      this._actionRequestsReplay.next(new PresentationResponse(PresentationEvent.isChatEnabled, message));
    }

    public isConferenceEnabled(message: boolean) {
      this._actionRequestsReplay.next(new PresentationResponse(PresentationEvent.isConferenceEnabled, message));
    }

    public conferenceCanConnect(message: boolean) {
      this._actionRequestsReplay.next(new PresentationResponse(PresentationEvent.ConferenceCanConnect, message));
    }


}
