/**
 * Created by sfraoua on 22/02/2018.
 */
import {Message} from "@app/core/messaging/message";
import {SessionState} from "@app/shared/models/session";

export class DrawAction extends Message{
    actionType : DrawActionType;
    color : string;
    x : number;
    y : number;
}


export enum DrawActionType {
    STARTED = 0,
    ADD = 1,
    ENDED = 2,
    CANCELED = 3,
    CLEAR = 4,
}