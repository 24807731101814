import {Component, Input, OnInit} from '@angular/core';
import {Session, SessionType} from '@app/shared/models/session';
import {OpentokConnectionStatus, OpentokService} from '@app/home/session/conference/opentok.service';
import {Logger} from "@app/core/logger.service";
import {Subscription, fromEvent} from "rxjs";
import {ResizeEvent} from 'angular-resizable-element';
import {MessageInterface} from "@app/core/messaging/message";
import {AllowStreaming} from "@app/core/messaging/allow-streaming";
import {MessagingService} from "@app/core/messaging/messaging.service";
import {AuthenticationService} from "@app/core/authentication/authentication.service";
import {TranslateService} from "@ngx-translate/core";
import {FlashService} from "@app/shared/flash/flash.service";
import {UtilService} from '@app/shared/service/util.service';
import {
    PresentationEvent,
    PresentationEventsService,
    PresentationResponse
} from "@app/home/session/presentation/presentation-events.service";
import {BoundingRectangle} from "angular-resizable-element/interfaces/bounding-rectangle.interface";
import {BowserService} from 'ngx-bowser';
import * as OT from '@opentok/client';
import {BrowserService} from '@app/shared/service/browser.service';
import {ConferenceEnableCommand} from '@app/core/messaging/conference-enable-command';

const logger = new Logger('Opentok');

@Component({
    selector: 'app-conference',
    templateUrl: './conference.component.html',
    styleUrls: ['./conference.component.scss']
})
export class ConferenceComponent implements OnInit {

    @Input() session: Session;
    @Input() isOCE: boolean;
    public canStream: boolean = false;
    public canResize: number = 0;
    private _translations: string[] = [];
    private _userNameStriped: boolean = false;

    private subscriptions: Subscription[] = [];
    public streamModal: boolean = false;
    private _conferenceSession: OT.Session;
    private _isConferenceEnabled: boolean = false;
    private _videoParam: boolean = false;


    constructor(private opentokService: OpentokService,
                private authService: AuthenticationService,
                private translateService: TranslateService,
                private conferenceService: OpentokService,
                private flashService: FlashService,
                private eventsService: PresentationEventsService,
                private messagingService: MessagingService,
                private _browserService: BrowserService,
                private _utilService: UtilService
    ) {


         this.translateService.get('not supported browser version for OpenTok')
           .subscribe((trans: string) => this._translations['not supported browser version for OpenTok'] = trans);

        if (this._browserService.isDesktop()) {
          this.canResize = 5;
        } else {
          this.canResize = 0;
        }

        // safari browser need param video set to true
        if (this._browserService.isSafari()) {
          this._videoParam = true;
        }

        this.subscriptions.push(
          this.eventsService.actionRequestsReplay.subscribe(
            (action: PresentationResponse) => {
              switch (action.event) {
                case PresentationEvent.isConferenceEnabled:
                  this._isConferenceEnabled = action.data;
                  if (this._isConferenceEnabled) {
                    this.checkMediaAccessibility();
                  }
                  this._toggleConferenceConnection(action.data);
                  break;
              }
            })
        );

        //listen to presentation events
        this.subscriptions.push(
            this.eventsService.actionRequests.subscribe(
                (response: PresentationResponse) => {
                    switch (response.event) {
                        case PresentationEvent.ToggleMicro:
                            this.conferenceService.muteUnmutePublisher();
                            break;
                        case PresentationEvent.ToggleCamera:
                            this.conferenceService.togglePublisherVideo();
                            break;
                    }
                }
            )
        );

        //listen to xmpp messages
        this.subscriptions.push(this.messagingService.Messages
            .subscribe((message: MessageInterface) => {
              switch (message.constructor) {
                case AllowStreaming:
                  this.isOCE = this.session.sessionType === SessionType.RemoteOCE;
                  // Forcing OCE mode
                  if (this._utilService.forcedStandalone()) {
                    this.isOCE = true;
                  }
                  const alloStreamMessage = <AllowStreaming>message;
                  if (this.isOCE && alloStreamMessage.streamerUid && !this._userNameStriped) {
                    this._userNameStriped = true;
                    this.authService.credentials.username = this.authService.credentials.username.match(/_(.*?)@/).pop();
                  }
                  if (message instanceof AllowStreaming && message.streamerUid === this.authService.credentials.username) {

                    if (this.canStream) {
                      this.eventsService.streamingDisabledNotify();
                      this.conferenceService.stopPublishing();
                      this.streamNotificationModal();
                      this.canStream = false;
                      return;
                    }

                    this.eventsService.streamingAllowedNotify();
                    this.canStream = true;
                    this.conferenceService.startPublishing();
                    this.streamNotificationModal();

                  } else {
                    if (message instanceof AllowStreaming && this.canStream && message.streamerUid !== undefined &&
                        message.streamerUid === this.authService.credentials.username) {
                      this.eventsService.streamingDisabledNotify();
                      this.conferenceService.stopPublishing();
                      this.canStream = false;
                      this.streamNotificationModal();
                      return;
                    }
                  }
                  break;
                case ConferenceEnableCommand:
                    const isConference = <ConferenceEnableCommand>message;
                    this._isConferenceEnabled = isConference.isConferenceEnabled;
                    if (this._isConferenceEnabled) {
                       this.checkMediaAccessibility();
                    }
                    this.eventsService.conferenceCanConnect(this._isConferenceEnabled);
                  break;
               }
            })
        );

        //subscribe to opentok service messages
        this.subscriptions.push(
            this.opentokService.conferenceEvents.subscribe(
                (status: OpentokConnectionStatus) => {
                    switch (status) {
                        case OpentokConnectionStatus.Disabled:
                            this.canStream = false;
                            this.eventsService.streamingDisabledNotify();
                            break;
                        case OpentokConnectionStatus.Disconnected:
                            this.eventsService.streamingDisabledNotify();
                            this.canStream = false;
                            /* this.translateService.get("Stream has been disconnected.").subscribe(
                                ((txt: string) => {
                                    this.flashService.warning(txt);
                                })
                            );*/
                            break;
                        case OpentokConnectionStatus.Reconnected:
                            this.translateService.get("You joined the audio/video conference.").subscribe(
                                ((txt: string) => {
                                    this.flashService.info(txt);
                                })
                            );
                            break;
                        case OpentokConnectionStatus.Reconnecting:
                            this.translateService.get("Trying to reconnect to the audio/video conference.").subscribe(
                                ((txt: string) => {
                                    this.flashService.warning(txt);
                                })
                            );
                            break;
                        case OpentokConnectionStatus.PublishingError:
                            this.canStream = false;
                            this.eventsService.streamingDisabledNotify();
                            this.translateService.get("Unable to start publishing.").subscribe(
                                ((txt: string) => {
                                    this.flashService.error(txt);
                                })
                            );
                            break;
                        case OpentokConnectionStatus.Error:
                            this.canStream = false;
                            this.eventsService.streamingDisabledNotify();
                            this.translateService.get("Error connecting to the session.").subscribe(
                                ((txt: string) => {
                                    this.flashService.error(txt);
                                })
                            );
                            break;
                    }
                })
        );

    }

    ngOnInit() {
        // check if the browser is able to run openTok
        if (this.opentokService.getOT().checkSystemRequirements()) {
          this.connectToConference();
        }
    }

    ngOnDestroy() {
        this.opentokService.disable();
        this.eventsService.streamingDisabledNotify();
        this.subscriptions.forEach((sub: Subscription) => sub.unsubscribe());
    }

    onResizeEnd(event: ResizeEvent, elemId: any): void {
        const elem: HTMLElement = document.getElementById(elemId);

        if (!elem) {
          return;
        }

        const rect = this.refreshVideoBounds(event.rectangle, elemId);

        // resize only for the desktop
        if (this._browserService.isDesktop()) {
          elem.style.width = rect.width + 'px';
          elem.style.height = rect.height + 'px';
          // update the height/width on resizing in desktop mode
          const firstChild = <HTMLElement>elem.firstChild;
          firstChild.style.height = '100%';
          firstChild.style.width = '100%';
        }

        if (rect === null) {
            return;
        }

        elem.style.left = rect.left + 'px';
        elem.style.top = rect.top + 'px';
        elem.style.position = 'fixed';
    }

    checkMediaAccessibility(): void {

      // check if the browser is able to run openTok for IE 11
      if (this._browserService.isIE()) {
        if (!this.opentokService.getOT().checkSystemRequirements()) {
          this.opentokService.getOT().upgradeSystemRequirements();
        }
      }
    }

    getStreams(): OT.Stream[] {
        return this.opentokService.streams;
    }

    isPublishingVideo() {
        if (this.opentokService.publisher && this.opentokService.publishing)
            return this.opentokService.publisher.stream && this.opentokService.publisher.stream.hasVideo;
    }

    private connectToConference() {
        const key = this.isOCE ? this.session.sessionKey.substring(0, 6) : this.session.id;
        // this.opentokService.checkConnectivity(`${key}`, this.isOCE);
        this.opentokService.initSession(`${key}`, false, this.isOCE).subscribe(
            (session: OT.Session) => {
              logger.info(session);
              this.eventsService.conferenceCanConnect(this._isConferenceEnabled);
            });
    }

    private refreshVideoBounds(rect: BoundingRectangle, elementId: string = null): BoundingRectangle {
        if (rect.left < 0)
            rect.left = 0;

        if (rect.top < 0)
            rect.top = 0;

        if (rect.left > (window.innerWidth- rect.width))
            rect.left = window.innerWidth - rect.width;

        if (rect.top > (window.innerHeight - rect.height))
            rect.top = window.innerHeight - rect.height;

        //Try to avoid other videos overlap
        if(elementId) {

            let videos : HTMLCollectionOf<Element> = document.getElementsByClassName('kad-conference');



            for (var i = 0; i < videos.length; i++) {
                let rect1 =  videos[i].getBoundingClientRect();


                if(videos[i].id === elementId){
                    continue;
                }


                let overlap = !(rect1.right < rect.left ||
                    rect1.left > rect.right ||
                    rect1.bottom < rect.top ||
                    rect1.top > rect.bottom);

                if (overlap && !this._browserService.isDesktop())
                {
                    return null;
                }

            }
        }

        return rect;
    }

    public windowResized(streamDom : HTMLElement) {
        let rect : BoundingRectangle = this.refreshVideoBounds({
            top : streamDom.offsetTop,
            left : streamDom.offsetLeft,
            right: 0,
            bottom: 0,
            width: streamDom.offsetWidth,
            height: streamDom.offsetHeight,
        });

        streamDom.style.left = rect.left + "px";
        streamDom.style.top = rect.top + "px";
        streamDom.style.position = "fixed";

    }


    public streamNotificationModal(): void {
      this.streamModal = true;
      setTimeout( () => {
        this.streamModal = false;
      },4000);
    }


    private _checkAudioPermission(): void {
      if (!this._browserService.isIE()) {
        if (navigator.mediaDevices) {
          // force asking for mic/cam permission
          navigator.mediaDevices.getUserMedia({audio: true});
        } else {
          const message = this._translations['not supported browser version for OpenTok'];
          this.flashService.warning(message, 5000);
        }
      }
    }

    private _confenrenceConnect(): void {
      this.opentokService.connect().then((session: OT.Session) => {
        this.translateService.get('You joined the audio/video conference.').subscribe(
          (text: string) => {
            logger.info(session);
            this._conferenceSession = session;
            this.flashService.info(text);
          }
        );
      });
    }

    /**
     *
     * @param {boolean} connect
     */
    private _toggleConferenceConnection(connect: boolean): void {

      this.subscriptions.push(
        this.eventsService.actionRequestsReplay.subscribe(
          (action: PresentationResponse) => {
            switch (action.event) {
              case PresentationEvent.ConferenceCanConnect:
                if (this._isConferenceEnabled && !this.canStream  && !this._conferenceSession) {
                  if (this._browserService.isIE()) {
                    this._confenrenceConnect();
                  } else {
                    if (navigator.mediaDevices) {
                      // force asking for mic/cam permission
                      // init video param to false to not keep the camera alight after disconnecting the stream
                      // in safari  if the video param is set to false we cant access to camera's browser
                      navigator.mediaDevices.getUserMedia({audio: true, video: this._videoParam}).then(() => {
                        this._confenrenceConnect();
                      });
                    } else {
                      const message = this._translations['not supported browser version for OpenTok'];
                      this.flashService.warning(message, 5000);
                    }
                  }
                } else if (!this._isConferenceEnabled && this.canStream || !this._isConferenceEnabled && this._conferenceSession) {
                  this.eventsService.streamingDisabledNotify();
                  this.conferenceService.stopPublishing();
                  this.translateService.get('The audio/video conference has been stopped').subscribe(
                    (text: string) => {
                      this._conferenceSession = null;
                      this.flashService.warning(text);
                      setTimeout( () => {
                        this.conferenceService.disable();
                      }, 1000);
                    }
                  );
                }
                break;
            }
          })
      );
    }
}
