/**
 * Created by sfraoua on 22/02/2018.
 */
import {Message} from '@app/core/messaging/message';
import {ScreenSize} from '@app/core/messaging/screen-size';
import {VideoAction} from '@app/core/messaging/video-action';



export class LoadSlideOld extends Message {
        version_id: string;
        path: string;
        real_path: string;
        timestamp: string;
        username: string;
        size: ScreenSize;
        video: VideoAction[];
}

export class LoadSequence extends Message {
    sequenceIdentifier: number;
    presentationIdentifier: string;
    sender: string;
    size: ScreenSize;
    dynamicContent: string;
    sequenceURL: string;
    timestamp: string;
    video: VideoAction[];
}

export class LoadSlide extends Message {
  sequenceIdentifier: number;
  presentationIdentifier: string;
  sender: string;
  size: ScreenSize;
  dynamicContent: string;
  slideURL: string;
  slideName: string;
  timestamp: string;
  video: VideoAction[];
}
