import {Injectable} from '@angular/core';
import {AuthenticationService} from '@app/core/authentication/authentication.service';
import {ActivatedRoute, Router} from '@angular/router';
import {BrowserService} from '@app/shared/service/browser.service';
import {ThanksPageService} from '@app/thanks-page/thanks-page.service';
import {ChatMessage} from '@app/core/messaging/chat-message';
import {TranslateService} from '@ngx-translate/core';
import {Observable} from 'rxjs/Observable';
import {ReplaySubject} from 'rxjs/ReplaySubject';

declare const NoSleep: any;
declare const ActiveXObject: any;

@Injectable()
export class UtilService {

    private isForcedStandalone: boolean;

    /**
     * @function Constructor
     * @param {AuthenticationService} _authService
     * @param {Router} _router
     * @param {ActivatedRoute} _route
     * @param {BrowserService} _browserService
     * @param {ThanksPageService} _thanksPageService
     */
    constructor(
        private _authService: AuthenticationService,
        private _router: Router,
        private _route: ActivatedRoute,
        private _browserService: BrowserService,
        private _thanksPageService: ThanksPageService,
        private translateService: TranslateService
    ) {
        this._route.queryParamMap.subscribe(queryParams => {
            this.isForcedStandalone = queryParams.get('forceStandalone') === 'true';
        });
    }

    /**
     * @function _doLogout
     * @description
     * @private
     * @param {string} path
     * @returns {void}
     */
    public _doLogout(path: string = '/exit'): void {
      this._authService.logout().subscribe(() => {
          // For external HTTP redirection
          if (path === '/exit' && this._thanksPageService.hasExternalRedirection) {
              this._thanksPageService.redirect();
          }
          // For internal path redirection
          else {
              this._router.navigate([path], { replaceUrl: true });
          }
      });
    }

    /**
     * @function getNoSleepJs
     * @description
     * @public
     * @returns {any}
     */
    public getNoSleepJs(): any {
        return new NoSleep();
    }

    /**
     * @function isAcrobatReaderInstalled
     * @description
     * @public
     * @returns {boolean}
     */
    public isAcrobatReaderInstalled(): boolean {
        const isIE = this._browserService.isIE();
        function getActiveXObject(name: string) {
            try {
                return new ActiveXObject(name);
            } catch (e) {}
        }
        function getPlugin(name: string) {
            for (const key in navigator.plugins) {
                const plugin = navigator.plugins[key];
                if (plugin.name === name) {
                    return plugin;
                }
            }
        }
        function getPdfPlugin() {
            return function () {
                if (isIE) {
                    return getActiveXObject('AcroPDF.PDF') ||
                        getActiveXObject('PDF.PdfCtrl');
                } else {
                    return getPlugin('Adobe Acrobat') ||
                        getPlugin('Chrome PDF Viewer') ||
                        getPlugin('WebKit built-in PDF');
                }
            }();
        }
        return !!getPdfPlugin();
    }

    /**
     * @function forcedStandalone
     * @description
     * @public
     * @returns {boolean}
     */
      public forcedStandalone(): boolean {
          return this.isForcedStandalone;
      }

    /**
     * @function setUserChatNickName
     * @description
     * @public
     * @param{ChatMessage} message
     * @returns {boolean}
     */
    public setUserChatNickName(message: ChatMessage): Observable<string> {
      const subject = new ReplaySubject<string>();
      if (message.user) {
        if (message.user.first_name && message.user.last_name) {
          subject.next(message.user.first_name + ' ' + message.user.last_name);
        } else if (message.user.first_name) {
          subject.next(message.user.first_name);
        } else if (message.user.last_name) {
          subject.next(message.user.last_name);
        } else {
          this.translateService.get('guest').subscribe((text: string) =>  {
            subject.next(text);
            });
        }
      } else {
         this.translateService.get('guest').subscribe((text: string) => {
           subject.next(text);
        });
      }
      return subject.asObservable();
    }

}
