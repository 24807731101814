import {
    AfterViewInit,
    Component,
    ElementRef, HostListener,
    Input,
    OnDestroy,
    OnInit,

    ViewChild
} from '@angular/core';
import {Session, SessionAudioType, SessionState} from '@app/shared/models/session';
import {Subscription} from "rxjs";
import {ContentVersion} from "@app/shared/models/content_version";
import {Logger} from "@app/core/logger.service";
import {HeaderService} from '@app/core/shell/header/header.service';
import {
    PresentationEvent,
    PresentationEventsService,
    PresentationResponse
} from '@app/home/session/presentation/presentation-events.service';
import {NotificationsService} from 'angular2-notifications';
import {ChatMessage} from '@app/core/messaging/chat-message';


import {SDK_EVENTS, SdkService} from '@app/shared/service/sdk.service';
import {ScreenSize} from '@app/core/messaging/screen-size';
import {LoadSlide} from '@app/core/messaging/load-slide';
import {Notification} from '@app/core/messaging/notification';
import {BrowserService} from '@app/shared/service/browser.service';
import {UtilService} from '@app/shared/service/util.service';


const logger = new Logger("Presentation");

declare var Mustache: any;

@Component({
    selector: 'app-presentation',
    templateUrl: './presentation.component.html',
    styleUrls: ['./presentation.component.scss']
})
export class PresentationComponent implements OnInit, OnDestroy, AfterViewInit {


    @Input() contentBasePath: string;
    @Input() session: Session;
    @Input() contents: ContentVersion[];

    @ViewChild('contentIFrame') contentIFrame: ElementRef;

    private _subscriptions: Subscription[] = [];


    private sdk: any;

    public notificationsOptions: any = {
        position: ['bottom', 'right'],
        lastOnBottom: true,
        timeOut: 3000,
        showProgressBar: false,
        clickToClose: true,
        maxLength: 255, // Seems not to work
        maxStack: 3,
        theClass: ''
    };

    public actionBarsDisplayed: boolean;
    private _movingTimeout: number;
    private _allMouseMovePoints: { x: number, y: number }[] = [];

    @HostListener('mousemove', ['$event'])
        onMousemove(event: MouseEvent) {
            if (this._browserService.isDesktop()) {
                this._allMouseMovePoints.push({ x: event.pageX, y: event.pageY });
                const firstPoint = this._allMouseMovePoints[0];
                const lastPoint = this._allMouseMovePoints[this._allMouseMovePoints.length - 1];
                // Mouse have to be moved more than 50px in order to display the action bars
                if (Math.abs(lastPoint.x - firstPoint.x) > 50 || Math.abs(lastPoint.y - firstPoint.y) > 50) {
                    this._allMouseMovePoints = [];
                    this._autoTogglingActionBar();
                }
            }
        }

    @HostListener('touchstart', ['$event'])
        onTouchStart(event: any) { // TouchEvent does not exist in Safari
            if (!this._browserService.isDesktop()) {
                this._autoTogglingActionBar();
            }
        }

    /**
     * @function constructor
     * @param {PresentationEventsService} _presActionsService
     * @param {HeaderService} headerService
     * @param {NotificationsService} _notificationsService
     * @param {SdkService} _sdkService
     */
    constructor(
        private _presActionsService: PresentationEventsService,
        private headerService: HeaderService,
        private _notificationsService: NotificationsService,
        private _sdkService: SdkService,
        private _browserService: BrowserService,
        private _utilService: UtilService
    ) {
        this._subscriptions.push(
            this._presActionsService.actionRequests.subscribe((action: PresentationResponse) => {
                switch (action.event) {
                    case PresentationEvent.notification:
                        this._displayNotification(action.data);
                        break;
                  }
            })
        );
    }

    /**
     * @function _autoTogglingActionBar
     * @description
     * @private
     * @return {void}
     */
    private _autoTogglingActionBar(): void {
        this.actionBarsDisplayed = true;
        clearTimeout(this._movingTimeout);
        this._movingTimeout = setTimeout(() => {
            this.actionBarsDisplayed = false;
        }, 5000);
    }

    /**
     * @function _displayNotification
     * @description
     * @private
     * @param {any} message
     * @return {void}
     */
    private _displayNotification(message: any): void {
        if (message instanceof ChatMessage) {
            this._displayChatNotification(message);
        }
    }

    /**
     * @function _displayNotification
     * @description
     * @private
     * @param {ChatMessage} message
     * @return {void}
     */
    private _displayChatNotification(message: ChatMessage): void {
        setTimeout(() => {
          this._utilService.setUserChatNickName(message).subscribe( (user_name: string) => {
            this._notificationsService.bare(user_name, message.message, { theClass: 'chat-notif' });
          });
        }, 500);
    }


    /**
     * @function ngOnInit
     */
    ngOnInit() {
        this.headerService.changeVisibilty(false);
    }

  /**
     * @function ngOnDestroy
     */
    ngOnDestroy() {
        this._subscriptions.forEach((subscription: Subscription) =>
        subscription.unsubscribe()
        );
    }

    ngAfterViewInit() {

      this._sdkService.injectScript().then(() => {
        this.sdk = this._sdkService.sdk;
        this.sdk.getPlayerJS().initModulesHTML();

        setTimeout( () => {
          // TODO need to fix the viewer to send an XMPP loadSlide at session create
          // if we don't receive a load slide. we need to check if the IFrame url is not set to
          // send an load slide event to the player
          if (!this.sdk.getPlayerJS().isIFrameUrl() && this.contents !== undefined && this.contents.length > 0) {

            const currentSlideUrl = this.contentBasePath + '/' + this.contents[0].version_id + this.contents[0].slides[0].archive_resource_uri;
            const screenSize = new ScreenSize();
            let contentHeight = screenSize.height;
            let contentWidth = screenSize.width;
            if (this.contents[0].slides[0]['size'] && this.contents[0].slides[0]['size'].height && this.contents[0].slides[0]['size'].width) {
              contentHeight = this.contents[0].slides[0]['size'].height;
              contentWidth = this.contents[0].slides[0]['size'].width;
            }
            const message = new LoadSlide()
            message.url = currentSlideUrl;
            message.size = screenSize;
            message.size.height = contentHeight;
            message.size.width = contentWidth;
            message.type = 'load-slide';
            // this.sdk.getPlayerJS().eventDispatcher(<LoadSlide>message);
          }
        }, 6000);
      });
    }

  /**
   * @function showConference
   * @description
   * @public
   * @returns {boolean}
   */
   showConference(): boolean {
     return (!!this.session && this.session.audioType === SessionAudioType.Isharing);
   }
}
