import {AfterViewInit, Component, Directive, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {PresentationEvent, PresentationEventsService, PresentationResponse} from '@app/home/session/presentation/presentation-events.service';
import {Subscription} from 'rxjs';
import {ContentVersion} from '@app/shared/models/content_version';
import {AuthenticationService} from '@app/core/authentication/authentication.service';
import {MessagingService} from '@app/core/messaging/messaging.service';
import {RelatedContent} from '@app/shared/models/related_content';
import {TabsetComponent} from 'ngx-bootstrap';
import * as _ from 'lodash';
import {AttendeesService} from '@app/shared/service/attendees.service';
import {RelatedContentVisibility} from '@app/core/messaging/display-related-content';
import {Session} from '@app/shared/models/session';
import {SessionService} from '@app/shared/service/session.service';
import {MandatoryFileStatus, RemoteRelatedFile} from '@app/core/messaging/remote-related-file';

@Component({
    selector: 'app-drawer',
    templateUrl: './drawer.component.html',
    styleUrls: ['./drawer.component.scss']
})
export class DrawerComponent implements OnInit, AfterViewInit {

    private actionSubscription: Subscription;
    public collapse: boolean = true;
    public customClass: string = 'content-header';
    public tabClass: string = 'tabClass';
    public access_token: string;
    public search: string;
    public contentsArray: any = []; //ContentVersion[] = [];
    public searchRelated: string;
    public currentDrawerAccordionsOpeningState: any = {};
    public currentRelatedAccordionsOpeningState: any = {};
    public lastDrawerAccordionsOpeningState: any = {};
    public lastRelatedAccordionsOpeningState: any = {};
    public hasPresenterMode: boolean = false;
    public hasInteractiveMode: boolean = false;
    public activeSlide: any[][] = [];
    public remoteRelatedArray: RemoteRelatedFile[] = [];

    private _connectedJid: string = null;
    private _isSpeaker: boolean = false;
    private _isExpert: boolean = false;

    public oneAtATime: boolean = true;

    @Input() session: Session;
    @Input() contents: ContentVersion[];
    @Input() actionBarsDisplayed: boolean;
    @ViewChild('drawerTabs') drawerTabs: TabsetComponent;

    /**
     * @function constructor
     * @param {PresentationEventsService} _eventsService
     * @param {AuthenticationService} _authService
     * @param {MessagingService} _messagingService
     */
    constructor(
        private _eventsService: PresentationEventsService,
        private _authService: AuthenticationService,
        private _messagingService: MessagingService,
        private _attendees: AttendeesService,
        private _sessionService: SessionService,
        private _attendeesService: AttendeesService
    ) {
        this.actionSubscription = this._eventsService.actionRequests
            .subscribe(
                (action: PresentationResponse) => {
                    switch (action.event) {
                        case PresentationEvent.collapseDrawer:
                            this.toggleDrawer();
                            break;
                        case PresentationEvent.askForHandNotification:
                            this.hasPresenterMode = action.data;
                            this.hasPresenterMode ? this._setPresenterAccess() : this._setParticipantAccess();
                            break;
                        case PresentationEvent.interactiveModeStatusNotification:
                            this.hasInteractiveMode = action.data;
                            // this._setParticipantAccess();
                            break;
                    }
                }
            );

      this._eventsService.actionRequestsReplay
        .subscribe((action: PresentationResponse) => {
          switch (action.event) {
            case PresentationEvent.remoteRelatedContentAction:
              this._setMandatoryFiles(<RemoteRelatedFile[]>action.data);
              break;
          }
        });
    }

    /**
     * @function ngOnInit
     */
    ngOnInit() {
        this.access_token = this._authService.credentials.access_token;
        this._connectedJid = this._authService.credentials.username;
        this._isSpeaker = this._sessionService.isSpeaker(this._connectedJid);
        //this._isExpert = this._attendeesService.isExpert(this._connectedJid);

        // get expert contents
        const expertArray = _.filter(this.contents, (p: ContentVersion) => this._attendees.isExpert(p.content_author.uid));
        // filter expert content by name
        this.contentsArray = _.orderBy(expertArray, ['name'], ['asc']);
        // get other contents (speaker)
        let speakerArray = _.reject(this.contents, (p: ContentVersion) => this._attendees.isExpert(p.content_author.uid));
        // filter speaker content by name
        // speakerArray = _.orderBy(speakerArray, ['name'], ['asc']);

        this.contentsArray.push(...speakerArray);

        // init the array of the current active slide
        this.contentsArray.forEach((elt: ContentVersion, index: number) => {
          const slideArray = [];
          for (let i = 0; i < elt.slides.length; i++) {
            slideArray[i] = false;
          }
          this.activeSlide[index] = slideArray;
        });

        this._setParticipantAccess();
        this._initAccordionsOpeningState();
    }

    /**
     * @function ngAfterViewInit
     */
    ngAfterViewInit() {}

    /**
     * @function _focusTab
     * @description
     * @private
     * @param {number} index
     * @param {boolean} bool
     * @return {void}
     */
    private _focusTab(index: number, bool: boolean): void {
        if (this.drawerTabs.tabs.length > 0 && this.drawerTabs.tabs[index]) {
          this.drawerTabs.tabs[index].active = bool;
        }
    }

    /**
    * @function _setMandatoryFiles
    * @description
    * @param {RemoteRelatedFile[]} relatedFiles
    * @private
    * @returns {void}
    */
    private _setMandatoryFiles(relatedFiles: RemoteRelatedFile[]): void {
      relatedFiles = _.filter(relatedFiles, (related: RemoteRelatedFile) =>  related.type === RelatedContentVisibility.PUBLIC_REMOTE );
    this.remoteRelatedArray = relatedFiles;
    }

    /**
     * @function remoteRelatedContentClick
     * @description
     * @public
     * @param {RemoteRelatedFile} related
     * @returns {void}
     */
    public remoteRelatedContentClick(related: RemoteRelatedFile): void {
        this._eventsService.remoteRelatedContentClickAction(related);
        this._messagingService.mandatoryFileNotification(related.name, MandatoryFileStatus.OPENED);
    }

    /**
     * @function _disableTab
     * @description
     * @private
     * @param {number} index
     * @param {boolean} bool
     * @return {void}
     */
    private _disableTab(index: number, bool: boolean): void {
      if (this.drawerTabs.tabs.length > 0) {
        this.drawerTabs.tabs[index].disabled = bool;
      }
    }

    /**
     * @function _setParticipantAccess
     * @description
     * @private
     * @return {void}
     */
    private _setParticipantAccess(): void {
        // Focusing related contents tab
        this._focusTab(1, true);
        // Deactivating main content tab
        this._disableTab(0, true);
    }

    /**
     * @function _setPresenterAccess
     * @description
     * @private
     * @return {void}
     */
    private _setPresenterAccess(): void {
        // Focusing related contents tab
        this._focusTab(0, true);
        // Deactivating main content tab
        this._disableTab(0, false);
    }

    /**
     * @function _initAccordionsOpeningState
     * @description
     * @private
     * @return {void}
     */
    private _initAccordionsOpeningState() {
        let firstContentIdWithRelatedDocs: number = null;
        for (let i = 0; i < this.contents.length; i++) {
            // Main content opening state
            if (i === 0) {
                this.currentDrawerAccordionsOpeningState[i] = true;
            } else {
                this.currentDrawerAccordionsOpeningState[i] = false;
            }
            // Related opening state
            if (this.contents[i].relateds.length) {
                if (firstContentIdWithRelatedDocs === null) {
                    firstContentIdWithRelatedDocs = i;
                    this.currentRelatedAccordionsOpeningState[i] = true;
                } else {
                    this.currentRelatedAccordionsOpeningState[i] = false;
                }
            }
        }
    }

    /**
     * @function toggleDrawer
     * @public
     * @return {void}
     */
    public toggleDrawer(): void {
        // back to slide tab
        if (this.drawerTabs.tabs.length > 0) {
            this.drawerTabs.tabs[0].active = true;
        }

        this.collapse = !this.collapse;
    }

    /**
     * @function loadSlide
     * @public
     * @param slide
     * @param {number} index
     * @return {void}
     */
    public loadSlide(slide: any, index: number, slideIndex: number): void {
        // init array of current active slide
        this.activeSlide.forEach( (elt) => {
          elt.fill(false);
        });
        // new current active slide
        this.activeSlide[index][slideIndex] = true;
        const size = slide.size;
        const slideURI = slide.archive_resource_uri;
        const contentVersion = this.contentsArray[index].version;
        const contentVersionID = this.contentsArray[index].version_id;

        this._messagingService.changeSlide(slideURI, contentVersion, contentVersionID, size);
        this.collapse = true;
    }

    /**
     * @function relatedContentClick
     * @public
     * @param {RelatedContent} related
     * @return {void}
     */
    public relatedContentClick(related: RelatedContent): void {
        this._eventsService.relatedContentClickAction(related);
        this.collapse = true;
    }

    /**
     * @function accordionStateChanged
     * @description
     * @public
     * @param {string} type
     * @param {number} index
     * @return {void}
     */
    public accordionStateChanged(type: string, index: number): void {
        if (type === 'main') {
            this.currentDrawerAccordionsOpeningState[index] = !this.currentDrawerAccordionsOpeningState[index];
            // Saving last accordions states
            this.lastDrawerAccordionsOpeningState = JSON.parse(JSON.stringify(this.currentDrawerAccordionsOpeningState));
        } else if (type === 'related') {
            this.currentRelatedAccordionsOpeningState[index] = !this.currentRelatedAccordionsOpeningState[index];
            // Saving last accordions states
            this.lastRelatedAccordionsOpeningState = JSON.parse(JSON.stringify(this.currentRelatedAccordionsOpeningState));
        }
    }

    /**
     * @function openAllAccordions
     * @description
     * @public
     * @param {string} type
     * @return {void}
     */
    public openAllAccordions(type: string): void {
        // Resetting all accordions states
        if (this.search && !this.search.length) {
            this.resetAllAccordionsPreviousState(type);
        }
        // Opening all accordions
        else {
            for (let i = 0; i < this.contents.length; i++) {
                if (type === 'main') {
                    this.currentDrawerAccordionsOpeningState[i] = true;
                } else if (type === 'related') {
                    this.currentRelatedAccordionsOpeningState[i] = true;
                }
            }
        }
    }

    /**
     * @function resetAllAccordionsPreviousState
     * @description
     * @public
     * @param {string} type
     * @return {void}
     */
    public resetAllAccordionsPreviousState(type: string): void {
        if (type === 'main') {
            this.currentDrawerAccordionsOpeningState = JSON.parse(JSON.stringify(this.lastDrawerAccordionsOpeningState));
        } else if (type === 'related') {
            this.currentRelatedAccordionsOpeningState = JSON.parse(JSON.stringify(this.lastRelatedAccordionsOpeningState));
        }
    }

    /**
     * @function shouldBeDisplayed
     * @description
     * @param {ContentVersion} related
     * @returns {boolean}
     */
    public shouldBeDisplayed(related: RelatedContent): boolean {
        // Is the related doc attached to an expert contents
        /*const fromExpert:boolean = false;
        this.session.members.find((member) => {
            return member.role === 'expert';
        }).memberDN;*/

        // this._isExpert

        // @TODO: /!\ DEAL WITH EXPERT BEHAVIOR /!\

        // Case Presenter with control
        if (this._isSpeaker && this.hasPresenterMode) {
            if (related.type === RelatedContentVisibility.PUBLIC || related.type === RelatedContentVisibility.PRIVATE || related.type === RelatedContentVisibility.SHARED) {
                return true;
            }
            return false;
        }
        // Case Presenter without control
        else if (this._isSpeaker && !this.hasPresenterMode) {
            if (related.type === RelatedContentVisibility.PUBLIC || related.type === RelatedContentVisibility.PRIVATE) {
                return true;
            }
            return false;
        }
        // Case Participant without control
        else if (!this._isSpeaker && !this.hasPresenterMode) {
            if (related.type === RelatedContentVisibility.PUBLIC) {
                return true;
            }
            return false;
        }
        // Case Participant with control
        else if (!this._isSpeaker && this.hasPresenterMode) {
            if (related.type === RelatedContentVisibility.PUBLIC) { // || related.type === RelatedContentVisibility.SHARED
                // @TODO: ADD SHARED when participant will be able to send shared related docs to others
                return true;
            }
            return false;
        }
    }

}
